import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Home from '../views/Home.vue'
import Partners from '../views/Partners.vue'
import partnerDetail from '@/components/partnerDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,},
    
      {
        path: '/partners/:name',
        name: 'partnerdetail',
        //component: () => import('../views/partnerDetail.vue'),
        component: partnerDetail,
        props: true
      }
    
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
