<template>
  <div id="app">
    <com-header></com-header>
    <router-view></router-view>
    <com-footer></com-footer>
  </div>
</template>

<script>

import ComHeader from './components/Header.vue'
import ComFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    ComHeader,
    ComFooter
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #fff;
  background-color: #000;
}
</style>

