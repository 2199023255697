<template>
  <header class="head">
    <nav class="navbar fixed-top navbar-expand-lg">
    <div class="topbar">
      ✨ KYL Official ERC-20 Contract is <a href="https://etherscan.io/address/0x5d07F52aE5f779422447C2A32F58c93A15E7d6F2" target="_blank">0x5d07F52aE5f779422447C2A32F58c93A15E7d6F2</a>
    </div>
    <div class="container">
      <a class="navbar-brand" href="/">
        <img alt="Vue logo" src="../assets/logo.svg">
      </a>
      <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <svg t="1640949725193" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1270" width="48" height="48"><path d="M768 373.333333H213.333333a32 32 0 0 1 0-64h554.666667a32 32 0 0 1 0 64zM768 576.426667H213.333333A32 32 0 1 1 213.333333 512h554.666667a32 32 0 0 1 0 64zM768 779.52H213.333333a32 32 0 0 1 0-64h554.666667a32 32 0 0 1 0 64z" p-id="1271" data-spm-anchor-id="a313x.7781069.0.i0" class="" fill="#ffffff"></path></svg>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" :class="[activeIndex==1 ?'active':'default']" @click="goAnchor('#technology')">Technology</a>
          </li>
          <li class="nav-item dropdown"> 
            <a class="nav-link" @click="goAnchor('#crowdloan')">Crowdloan</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="[activeIndex==3 ?'active':'default']" @click="goAnchor('#tokenomics')">Tokenomics</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Resources</a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
              <li><a class="dropdown-item" href="https://wiki.kylin.network/">Project Wiki</a></li>
              <li><a class="dropdown-item" href="https://docsend.com/view/kfrc2q57c3fwwkzs">Whitepaper</a></li>
              <li><a class="dropdown-item" href="https://docsend.com/view/2fwcfhs8mbyjnqcy">Overview Deck</a></li>
              <li><a class="dropdown-item" href="https://github.com/Kylin-Network">Github Repos</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Foundation
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink3">
              <li><a class="dropdown-item" @click="goAnchor('#meet')">Team & Advisors</a></li>
              <li><a class="dropdown-item" @click="goAnchor('#ecosystem')">Ecosystem</a></li>
              <li><a class="dropdown-item" @click="goAnchor('#press')">Press about Us</a></li>
              <li><a class="dropdown-item" @click="goAnchor('#roadmap')">Roadmap</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="[activeIndex==4 ?'active':'default']" @click="goAnchor('#contact')">Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-md-block d-lg-none" :class="[activeIndex==5 ?'active':'default']"  href="https://swap.kylin.network/">Swap KYL</a>
          </li>
        </ul>
      </div>
      <a class="navbar-apps d-none d-lg-block" href="https://swap.kylin.network/">
      <div></div> Swap KYL</a>
    </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      activeIndex: '',
    }
  },
  methods: {
    goAnchor(selector) {
        document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
        });
    },
  },
}
</script>