<template>
  <div class="partner-details container">
    <div class="featured-img"><img :src="getImgpath(post.name)" alt=""></div>
    <div class="flex-box flex-column flex-md-row">
      <div class="description">
        <h2>{{ post.name }}</h2>
        <div class="desctext">{{ post.info}}</div>
      </div>
      <a :href="post.url" class="el-button el-button--danger is-round website" target="_blank">Visit Site</a>
    </div>
  </div>
</template>
<script>
import usersData from "../assets/more.json";

export default {
  props: ['website'],
  data() {
    return {
      allpartners: usersData,
      post: {},
			pname: this.$route.params && this.$route.params.name,
    };
  },
  name: "partnerdetail",
  mounted() {
		this.getPartner()
    window.scrollTo({
      top: 0
    })
  },
  methods: {
    getPartner() {
      for (let i = 0; i < this.allpartners.length; i++) {
			//	console.log(this.allpartners[i].name);
        if (this.pname === this.allpartners[i].name) {
          this.post = this.allpartners[i];
				//	console.log(this.post);
        }
      }
    },
    getImgpath(name) {
      return name ? require('../assets/partnersb/' + name + '-B.png') : '';
    },
  },
};
</script>
<style lang="less" scoped>
.partner-details {
	padding-top: 210px;
  margin-right: auto;
  margin-left: auto;
	.featured-img {
    display: flex;
    width: 100%;
    max-width: 1200px;
    min-height: 390px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 11px;
    background-color: rgba(255, 255, 255, 0.05);
    text-align: center;
    margin-right: auto;
    margin-left: auto;
	}
  .flex-box {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    //justify-content: center;
    align-items: flex-start;
    .description {
      max-width: 600px;
      margin-top: 3rem;
    }
    .website {
      display: block;
      margin-left: auto;
      margin-top: 3rem;
    }
  }
}
</style>