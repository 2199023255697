//import ElCollapseTransition from './collapse-transition.js';
import Collapseitem from './collapseitem.vue'
import Collapse from './collapse'
Collapse.Collapseitem = Collapseitem



Collapse.install = function(Vue) {
  //Vue.component(ElCollapseTransition);
  Vue.component(Collapse.name, Collapse);
};

export default Collapse