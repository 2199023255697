import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'

import  Collapse  from './components/collapse';
//import ElCollapseTransition from 'element-ui/src/transitions/collapse-transition';


import "@lottiefiles/lottie-player";
import { Fancybox } from "@fancyapps/ui";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "@fancyapps/ui/dist/fancybox.css";
import 'swiper/css/swiper.css'
//import "vue-mailchimp-email-signup-form/dist/vue-mailchimp-email-signup-form.css";
import { VueMailchimpEmailSignupForm } from "vue-mailchimp-email-signup-form";
import axios from 'axios'

import "./style/home.less"
Vue.component("vue-mailchimp-email-signup-form", VueMailchimpEmailSignupForm);
Vue.component('Collapse',Collapse);
//Vue.component('ElCollapseTransition',ElCollapseTransition);
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
Vue.prototype.$axios=axios;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
