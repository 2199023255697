<template>
  <div class="main">
    <section class="top d-flex" :style="{ height: clientHeight }">
      <div class="container">
        <div class="row justify-content-md-end">
          <div class="col col-md-6 text-end text-md-start">
            <h1>
              The Layer-1<br /><span>Data Infrastructure</span><br />for DeFi and Web3
            </h1>
            <h2>
              Building the Decentralized & Configurable Multilevel Oracles to Superpower the New Web.
            </h2>
            <div class="video-box">
              <div
                data-fancybox
                data-src="https://ob-public-bkt.s3.ap-northeast-1.amazonaws.com/www-res/kylin.mp4"
                class="video-link justify-content-end justify-content-md-start"
              >
                <svg
                  width="48"
                  height="48"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <g>
                    <title>Layer 1</title>
                    <path
                      id="svg_1"
                      fill="#E90373"
                      d="m24,0.6665c-12.88,0 -23.33334,10.4533 -23.33334,23.3333c0,12.88 10.45334,23.3334 23.33334,23.3334c12.88,0 23.3333,-10.4534 23.3333,-23.3334c0,-12.88 -10.4533,-23.3333 -23.3333,-23.3333zm-4.6667,33.8333l0,-21l14,10.5l-14,10.5z"
                    />
                  </g>
                </svg>
                <div class="video-text ms-4">
                  <h5>what is kylin?</h5>
                  <h6>watch the video</h6>
                </div>
              </div>
            </div>
            <div class="kylinimg-box">
              <img src="../assets/img/group9.png" alt="" class="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="partners">
      <p>Featured in</p>
      <img src="../assets/img/logos-featured.svg" alt="" />
    </section>
    <section class="technology" id="technology">
      <div class="title">Technology</div>
      <div class="container">
        <el-row :gutter="40">
          <el-col :xs="24" :sm="24" :md="12">
            <div class="grid-content">
              <h2 class="kymt-5">What is Kylin?</h2>
              <p class="kymt-2">
                Kylin Network is a synergistic multi-level oracle data infrastructure built on Polkadot, democratizing the whole chain of data management processes required by all web3 middleware. 
              </p>
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item name="1">
                  <template slot="title">
                    <img
                      src="../assets/img/icon-tech-1.svg"
                      class="filter"
                    />The Polkadot Middleware Blockchain
                  </template>
                  <div>
                    The Kylin Network is a layer 1 blockchain for distributed data management built with Polkadot parachain compatibility to inherit its security, scalability and interoperability as a composable framework for middlewares across chains. 
                  </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                  <template slot="title">
                    <img
                      src="../assets/img/icon-tech-2.svg"
                      class="filter"
                    />The Decentralized Data Economy
                  </template>
                  <div>
                    A DeData world means a P2P orientation to the operationalization and monetization of data in various ways that are up to the imagination of the ecosystem participants. More users of the web are becoming more digitally aware of their data and are turning towards self-sovereign & self-custody solutions, Kylin is positioned to be the foundational data infrastructure that meets the anticipated demand.
                  </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                  <template slot="title">
                    <img
                      src="../assets/img/icon-tech-3.svg"
                      class="filter"
                    />Parachain & Substrate Compatibility
                  </template>
                  <div>
                    The core smart contracts are designed and built based on the Substrate framework of Polkadot. Kylin’s Canary Network, PICHIU, successfully deployed on the Polkadot testnet, Kusama.
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12">
            <div class="grid-content tech-right kymt-5">
              <el-tabs v-model="activeName" type="card">
                <el-tab-pane name="1">
                  <img src="../assets/img/tech-right1.png" alt="" />
                </el-tab-pane>
                <el-tab-pane name="2">
                  <img src="../assets/img/tech-right2.png" alt="" />
                </el-tab-pane>
                <el-tab-pane name="3">
                  <img src="../assets/img/tech-right3.png" alt="" />
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
        
        <p class="text-center kymt-5">
          <img src="../assets/img/tech-bottom.png" alt="" />
        </p>
      </div>
    </section>
    <section class="dataassets kymt-10">
      <div class="container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12">
            <h2 class="limt-w">The Data Backbone For All Web3 Middleware</h2>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="oracle">
      <div class="container">
        <el-row :gutter="40">
          <el-col :xs="24" :sm="24" :md="12" class="kymt-2">
            <h2>Kylin Oracle</h2>
            <p>
              The Kylin Network implements a multi-level, dynamically and statically sized oracle system, democratically created and managed,  to optimize for speed, persistence & security.
            </p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" class="text-center kymt-2">
            <img src="../assets/img/oracle.png" alt="" />
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :xs="24" :sm="24" :md="12" class="text-center kymt-5 analytic">
            <img src="../assets/img/analytics.png" alt="" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" class="kymt-5 kymt-right">
            <h2>Kylin DeData Marketplace</h2>
            <p>
              A free and open marketplace where tokenized off-chain or on-chain data streams as Semi-Fungible Tokens or Dynamic NFTs can be priced and exchanged. 
            </p>
            <p>
              Democracy Pallet<br />
              Enhanced Voting System<br />
              Dynamically & Statically Sized Oracles<br />
            </p>
          </el-col>
        </el-row>
        <h2 class="limt-w kymt-5">Build the Future of Decentralized Data</h2>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8">
            <el-card class="data-card">
              <img src="../assets/img/icon-1.svg" alt="" />
              <div>
                <span
                  >DeFi,<br />
                  NFT & DAO</span
                >
                <p>
                  Powering the infrastructure of the core adoption layers of
                  blockchain - DeFi, NFTs, and DAOs - through revolutionizing
                  data access and awareness
                </p>
              </div>
            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8">
            <el-card class="data-card">
              <img src="../assets/img/icon-2.svg" alt="" />
              <div>
                <span
                  >Web3.0<br />
                  Infrastructure</span
                >
                <p>
                  Powering the next wave of the information revolution as the
                  validated data rails of Web3
                </p>
              </div>
            </el-card>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8">
            <el-card class="data-card">
              <img src="../assets/img/icon-3.svg" alt="" />
              <div>
                <span
                  >Substrate &<br />
                  Polkadot Parachain</span
                >
                <p>
                  Through Polkadot’s innovative Layer 0 framework, Kylin Network
                  leverages Polkadot’s relay chain to validate various forms of
                  both on-chain and off-chain data
                </p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="crowdloan" id="crowdloan">
      <div class="title">Crowdloan</div>
      <div class="subtitle">Join Kylin Crowdloan</div>
      <div class="container">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8">
            <el-card class="crowdloan-card">
              <div class="crowdloan-card-title">Parachain Slot Auction</div>
              <p>
                Polkadot is a decentralized, multi-chain network that allows
                blockchains to connect for interoperability, scalability, and
                plug-and-play network security. To join Polkadot’s network, all
                parachains must participate in and win an auction to earn a slot
                on the network. Parachains can choose to raise DOT in a variety
                of ways in order to participate in the auction.
              </p>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8">
            <el-card class="crowdloan-card">
              <div class="crowdloan-card-title">Kylin Crowdloan Offering</div>
              <p>
                13.5% of $KYL total token supply available for the parachain auctions spanning across 6 different lease periods.
              </p>
              <p>
                Current Crowdloan:<br />
                33.3M KYL / 150k DOT = 222 KYL/DOT (~45.43% leasing return)
              </p>
              <p>
                <strong>1 DOT = 222 KYL</strong>
              </p>
            </el-card>
          </el-col>
        </el-row>
        <p class="text-center mt-5">
          <el-button type="danger" round class="crowdloan-button">
            <a href="https://parachains.info/details/kylin_network" target="_blank">Kylin is<br />Now on Polkadot</a>
          </el-button>
        </p>
        <div class="subtitle distribution">Kylin (KYL) Distribution</div>
        <p class="distribution-info">
          When Kylin wins a parachain slot auction, the network will begin to
          launch on Polkadot and Kylin will distribute KYL to crowdloan
          contributors.
        </p>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" class="mt-4 kylogo-l">
            <div class="rewords-items">
              <div class="rewords-item">
                <div class="rewords-title text-md-end">Total Rewards</div>
                <p class="text-md-end">
                  13.5% of total supply as Kylin Network crowdloan Reward to all
                  DOT contributors.
                </p>
              </div>
              <div class="rewords-item kymt-5">
                <div class="rewords-title text-md-end">Liquid</div>
                <p class="text-md-end">
                  30% rewards will be distributed when parachain launches.
                </p>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" class="text-center mt-5 kylogo">
            <img src="../assets/img/ky-logo.svg" alt="" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" class="mt-4 kylogo-r">
            <div class="rewords-items">
              <div class="rewords-item">
                <div class="rewords-title text-start">Early Bird Reward</div>
                <p class="text-start">
                  1% will be an early bird reward to the first 10% DOT
                  contributors.
                </p>
              </div>
              <div class="rewords-item kymt-5">
                <div class="rewords-title text-start">Vesting</div>
                <p class="text-start">
                  The remainin 70% rewards will be distributed by block.
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="tokenomics" id="tokenomics">
      <div class="title">Tokenomics</div>
      <div class="container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="tokenomics-item">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets9.lottiefiles.com/packages/lf20_4d2oiico.json"
                style="height: 180px"
              ></lottie-player>
              <div class="tokenomics-title">Staking</div>
              <p>
                All miners are required to stake KYL with a higher stake
                equating in a high probability of being selected to fulfill data
                requests.
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="tokenomics-item">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets8.lottiefiles.com/packages/lf20_pyooidvn.json"
                style="height: 180px"
              ></lottie-player>
              <div class="tokenomics-title">Governance</div>
              <p>
                KYL token holders are able to vote for the protocol upgrades and
                parameter changes on Kylin.
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="tokenomics-item">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src="https://assets2.lottiefiles.com/packages/lf20_ole7lwc9.json"
                style="height: 180px"
              ></lottie-player>
              <div class="tokenomics-title">Utility</div>
              <p>
                KYL tokens will be used to pay as a transaction, query fee, and
                also for data access behind paywall (private APIs).
              </p>
            </div>
          </el-col>
        </el-row>
        <div class="text-center small tokenlive">
          See live Kylin (KYL) token metrics on:
        </div>
        <p class="text-center">
          <a href="https://coinmarketcap.com/currencies/kylin/"
            ><img src="../assets/img/image4.svg" alt=""
          /></a>
        </p>
        <div class="token-price-container">
          <div class="token-price">
            <div class="price-item">
              <img src="../assets/img/icon-4.svg" alt="" />
              <div class="price-info">
                <div class="price-info-no" :ss="coinData.id">${{ coinData.price | num4Filter }}</div>
                <div class="price-info-detail">KYL Price</div>
              </div>
            </div>

            <div class="price-item">
              <img src="../assets/img/icon-5.svg" alt="" />
              <div class="price-info">
                <div class="price-info-no">${{ coinData.market_cap | numFilter | numberToCurrencyNo}}</div>
                <div class="price-info-detail">Circulating Market Cap</div>
              </div>
            </div>
            <div class="price-item">
              <img src="../assets/img/icon-6.svg" alt="" />
              <div class="price-info">
                <div class="price-info-no">${{ coinData.fully_diluted_market_cap | numFilter | numberToCurrencyNo }}</div>
                <div class="price-info-detail">Fully Diluted Market Cap</div>
              </div>
            </div>
            <div class="price-item">
              <img src="../assets/img/icon-7.svg" alt="" />
              <div class="price-info">
                <div class="price-info-no">${{ coinData.volume_24h | numFilter | numberToCurrencyNo }}</div>
                <div class="price-info-detail">24h Trading Volume</div>
              </div>
            </div>
            <div class="price-item">
              <img src="../assets/img/icon-8.svg" alt="" />
              <div class="price-info">
                <div class="price-info-no">{{ coinData.circulating_supply | numberToCurrencyNo }}</div>
                <div class="price-info-detail">Circulating Supply</div>
              </div>
            </div>
          </div>
          <p class="text-center exchange">Go to an exchange</p>
          <div class="token-brands-container">
            <div class="token-brands-item">
              <a
                href="https://www.kucoin.com/trade/KYL-USDT"
              >
                <img
                  src="../assets/img/kucoin_logo_hover.svg"
                  alt=""
                  class="exit"
                />
                <img
                  src="../assets/img/kucoin_logo.svg"
                  alt=""
                  class="exitno"
                />
              </a>
            </div>
            <div class="token-brands-item">
              <a href="https://www.gate.io/trade/kyl_usdt">
                <img
                  src="../assets/img/gote_logo_hover.svg"
                  alt=""
                  class="exit"
                />
                <img src="../assets/img/gote_logo.svg" alt="" class="exitno" />
              </a>
            </div>
            <div class="token-brands-item">
              <a href="https://hoo.com/transit/spot/kyl-usdt">
                <img
                  src="../assets/img/hoo_logo_hover.svg"
                  alt=""
                  class="exit"
                />
                <img src="../assets/img/hoo_logo.svg" alt="" class="exitno" />
              </a>
            </div>
            <div class="token-brands-item">
              <a href="https://www.hotbit.io/exchange?symbol=KYL_USDT">
                <img
                  src="../assets/img/hotbit_logo_hover.svg"
                  alt=""
                  class="exit"
                />
                <img
                  src="../assets/img/hotbit_logo.svg"
                  alt=""
                  class="exitno"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="case">
      <div class="container sticky-title">
        <div class="title">Use Cases</div>
      </div>
      <el-row>
        <el-col :xs="24" :sm="24" :md="12">
          <div class="case-item">
            <img src="../assets/img/case1.svg" alt="" />
            <div class="case-info">
              <div class="case-title">DeFi</div>
              <p>
                Within Kylin’s novel open sandbox oracle scripting environment,
                anyone can customize oracle scripting parameters to securely
                ‘self-feed’ the necessary data for any DeFi-based smart contract
                automations, with Oracle Scripters benefiting in perpetuity.
              </p>
            </div>
          </div>
          <div class="case-item">
            <img src="../assets/img/case2.svg" alt="" />
            <div class="case-info">
              <div class="case-title">NFT</div>
              <p>
                NFT is the metadata structure of Web3. Via our integration with
                NFT3, monetize query access to your personal data by interested
                parties. Via DDM/Os, own a fractionalized NFT of specific DeData
                markets.
              </p>
            </div>
          </div>
          <div class="case-item">
            <img src="../assets/img/case3.svg" alt="" />
            <div class="case-info">
              <div class="case-title">DAO</div>
              <p>
                Propose, hold, and form a DDM/O - a decentralized data
                marketplace/offering that operates as an independently held data
                trust among holders of a fractionalized NFT over specific
                marketplaces.
              </p>
            </div>
          </div>
          <div class="case-item">
            <img src="../assets/img/case4.svg" alt="" />
            <div class="case-info">
              <div class="case-title">Game</div>
              <p>
                Kylin will help GameFi build a credible economic ecology by
                allowing access to on and off-chain data, diversifying data
                streams, and ensuring their authenticity and accuracy.
              </p>
            </div>
          </div>
          <div class="case-item">
            <img src="../assets/img/case5.svg" alt="" />
            <div class="case-info">
              <div class="case-title">Machine<br />Learning (ML)</div>
              <p>
                In an open oracle scripting and DeData environment, Kylin
                Network will drive the next revolution in machine learning, as
                data sets can be freed from their siloization.
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
    <section class="roll">
      <div class="roll-container">
        <el-row :gutter="32" justify="center">
          <el-col :xs="24" :sm="24" :md="12" class="rollleft">
            <div class="roll-item">DEDATA</div>
            <div class="roll-item roll-item-black">DEDATA</div>
            <div class="roll-item">DEDATA</div>
            <div class="roll-item roll-item-black">DEDATA</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" class="rollright">
            <div class="roll-item roll-item-black">DEDATA</div>
            <div class="roll-item">DEDATA</div>
            <div class="roll-item roll-item-black">DEDATA</div>
            <div class="roll-item">DEDATA</div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="foundation">
      <div class="title" id="meet">Foundation</div>
      <div class="subtitle">The People behind Kylin</div>
      <div class="container">
        <el-row :gutter="32" class="foundation-row">
          <el-col :xs="24" :sm="24" :md="12" >
            <div>
              <div class="foundation-title">Strong and Talented Team</div>
              <p>
                The team and advisors consist of technical, marketing and
                financial talent coming out of top blockchain projects, schools
                and employers including Columbia University, Wharton, John
                Hopkins, Beam, TomoChain, JP Morgan and Credit Suisse.
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" class="text-end">
            <img src="../assets/img/brand-1.png" alt="" class="brand-1" />
          </el-col>
        </el-row>
        <el-row :gutter="32" class="kyspace">
          <el-col :xs="24" :sm="24" :md="12" class="backers">
            <img src="../assets/img/brand-2.svg" alt="" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" class="text-start text-md-end" >
            <div>
              <div class="foundation-title">Meet Our Strong Backers</div>
              <p  id="ecosystem">
                Kylin Network — the Polkadot Data Blockchain — has announced the
                closure of its seed and private round investment, raising $3.8
                million from Signum Capital, CMS Holdings, Digital Strategies,
                Rarestone. The round also included contributions from angel
                investors in North America, India, Israel, Asia, and the
                European Union.
              </p>
            </div>
          </el-col>
        </el-row>
        <div class="foundation-title">
          Ecosystem and Partners
        </div>
        <p>
          Kylin Network has had one of the fastest-growing partnership pipelines
          of any ecosystem due to its novel architecture, efficiencies and
          offerings as a Polkadot-validated data blockchain. Our many partners
          are excited to write their own oracle scripting parameters, have a
          secure and cost effective oracle solution, be able to efficiently
          query validated on and off-chain data, and take part in DeData
          Marketplace of various kinds.
        </p>
       
      </div>
      <div class="partners-container">
        <div class="shadow-partners"></div>
        <div class="shadow-partners shadow-partners-right"></div>
        <div class="partners-logo">
          <div class="partners-outer">
            <swiper
              :options="swiperOption"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
            <div
              v-for="el in logos"
              :key="el.id"
              class="swiper-slide"
            >
              <img :src="imgUrl(el)" />
            </div>
            </swiper>
          </div>
        </div>
      </div>

      <p class="text-center kymt-2 ">
        <el-button type="danger" round><router-link to="/partners" target="_blank">see all partners</router-link></el-button>
      </p>
      <p class="text-center kymt-2 d-none">
        <el-button type="info" round>see all partners</el-button>
      </p>
      
      <div class="container">
        <div class="member">
          <el-row :gutter="32" class="">
            <el-col :xs="24" :sm="24" :md="12" class="text-start">
              <div>
                <div class="foundation-title">
                  Founding Member of Polkadot DeFi Alliance
                </div>
                <p>
                  Polkadot DeFi Alliance is where industry-leading projects and
                  cutting edge teams come together to propel forward the larger
                  Polkadot ecosystem and the overall cryptocurrency industry. We
                  encourage collaboration, supporting the development of new
                  financial primitives, and integration of existing ones -
                  building towards wider adoption of decentralized finance.
                </p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" class="text-end">
              <img src="../assets/img/brand-7.svg" alt="" class="defi" />
            </el-col>
          </el-row>
          <p class="text-center kymt-2">
            <img src="../assets/img/brand-5.svg" alt="" />
          </p>
        </div>
        <div class="about" id="press">
          <div class="foundation-title">Press About Us</div>
          <p class="text-center kymt-2">
            <img src="../assets/img/brand-4.svg" alt="" />
          </p>
        </div>
      </div>
    </section>
    <section class="roadmap" id="roadmap">
      <div class="title">Roadmap</div>
      <div class="roadmap-container">
        <div class="road-box-outer">
          
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Over 52 Announced partners</p>
          </div>
          <div class="road-box road-box-2">
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Running live on Rococo and Kusama</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Producing blocks on Chachacha</p>
          </div>
          <div class="road-box road-box-2">
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Achieving XCMP Pallet calling</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Oracle feature and query functionality</p>
          </div>
          <div class="road-box road-box-2">
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Data warehouse storing data</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Achieved Parity #1 Milestone</p>
          </div>
          <div class="road-box road-box-2">
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Accepted into the Substrate Builders Program</p>
          </div>

          <div class="road-box road-box-1 road-red">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Massive Adoption and Scaling in DeFi, NFT & DAO</p>
          </div>
          <div class="road-box road-box-2 road-red">
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Massive Adoption and Scaling in Web3.0 Infrastructure</p>
          </div>
          <div class="road-box road-box-1 road-red">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🔭 Where We Are Going</p>
            <p>
              Massive Adoption and Scaling in Substrate & Polkadot Parachain
            </p>
          </div>
          <div class="road-box road-box-2 road-red">
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Unlock <span>1 Trillion</span> Dollar DeData Economy</p>
          </div>
          <div class="road-box road-box-1 road-red">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Bring <span>1 Billion</span> People to DeFi and Web 3.0</p>
          </div>

          <div class="road-box road-box-2">
            
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Over 52 Announced partners</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Running live on Rococo and Kusama</p>
          </div>
          <div class="road-box road-box-2">
            
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Producing blocks on Chachacha</p>
          </div>
          <div class="road-box road-box-1">
             <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Achieving XCMP Pallet calling</p>
          </div>
          <div class="road-box road-box-2">
           
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Oracle feature and query functionality</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Data warehouse storing data</p>
          </div>
          <div class="road-box road-box-2">
            
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Achieved Parity #1 Milestone</p>
          </div>
          <div class="road-box road-box-1">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🎖 Where We Are Now</p>
            <p>Accepted into the Substrate Builders Program</p>
          </div>

          <div class="road-box road-box-2 road-red">
            
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Massive Adoption and Scaling in DeFi, NFT & DAO</p>
          </div>
          <div class="road-box road-box-1 road-red">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Massive Adoption and Scaling in Web3.0 Infrastructure</p>
          </div>
          <div class="road-box road-box-2 road-red">
            
            <p class="milestone">🔭 Where We Are Going</p>
            <p>
              Massive Adoption and Scaling in Substrate & Polkadot Parachain
            </p>
          </div>
          <div class="road-box road-box-1 road-red">
            <img src="../assets/img/road.svg" alt="" class="imgline" />
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Unlock <span>1 Trillion</span> Dollar DeData Economy</p>
          </div>
          <div class="road-box road-box-2 road-red">
            <p class="milestone">🔭 Where We Are Going</p>
            <p>Bring <span>1 Billion</span> People to DeFi and Web 3.0</p>
          </div>


        </div>
      </div>
    </section>
    <section class="contact" id="contact">
      <div class="title">Partnership</div>
      <div class="container">
        <el-row>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="contact-item">
              <div class="contact-item-title">Dec, 4 2021</div>
              <img src="../assets/img/contact1.svg" alt="" />
              <p>Fireside chat Edward Snowden and Gavin Wood on BlockDown</p>
              <p>
                <a
                  class="readmore"
                  href="https://twitter.com/Kylin_Network/status/1466834544581955585?s=20"
                  >Read more</a
                >
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="contact-item">
              <div class="contact-item-title">Dec, 4 2021</div>
              <img
                src="../assets/img/contact2.png"
                alt=""
                width="325"
                height="182"
              />
              <p>
                Substrate Builders Program Milestone Update of Kylin Network
              </p>
              <p>
                <a
                  class="readmore"
                  href="https://www.parity.io/blog/substrate-builders-program-milestone-update-october-2021?utm_source=twitter&utm_medium=social&utm_campaign=sbp+update+october+2021&utm_content=parity"
                  >Read more</a
                >
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8">
            <div class="contact-item">
              <div class="contact-item-title">Dec, 4 2021</div>
              <img src="../assets/img/contact3.svg" alt="" />
              <p>
                Kylin Partners with Astar (First to Achieve XCMP Pallet Calling)
              </p>
              <p>
                <a
                  class="readmore"
                  href="https://kylinnetwork.medium.com/kylin-partners-with-astar-first-to-achieve-xcmp-pallet-calling-8c91474a826"
                  >Read more</a
                >
              </p>
            </div>
          </el-col>
        </el-row>
        <div class="kyspace">
          <vue-mailchimp-email-signup-form
          :element-id="'kylin-email-signup-form'"
          :url="'https://network.us14.list-manage.com/subscribe/post?u=b37fa0f2332f25cda48eb4f52&id=897d98fc13'"
          :title="'Get involved, be part of the community'"
          :subtitle="' Subscribe to receive our newsletters and exciting news.'"
        />
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import collapseitem from '../components/collapse/collapseitem.vue';
//import $ from 'jquery'
var logosContext = require.context("@/assets/logos/", false, /\.png$/);
//console.log(logosContext.keys())

export default {
  components: { collapseitem },

  created: function () {
    this.logos = logosContext.keys();
    // this.getCoinData();
    this.getKylData()
  },
  name: "Home",

  data() {
    return {
      input3: "",
      value1: "",
      clientHeight: "",
      open: false,
      activeName: "1",
      logos: [],
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: "#fff",
        backgroundStyle: "color",
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: "#3D4852",
            backgroundColor: "#fff",
            opacity: "0.7",
          },
          dark: {
            color: "#fff",
            backgroundColor: "#202020",
            opacity: "0.7",
          },
        },
      },
      swiperOption: {
        slidesPerView: 7,
        freeMode: true,
        speed:3000,
        observer: true,
        observeParents: true,
        autoplay: {
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: false,
        },
        loop: true,
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 6,
          }
  }
      },
      coinData: {
        id: 0,
        circulating_supply: 0,
        price: 0,
        market_cap: 0,
        fully_diluted_market_cap: 0,
        volume_24h: 0
      },
    };
  },
  filters: {
    numFilter(value) {
        let transformVal = Math.round(value).toFixed(1)
        let realVal = transformVal.substring(0, transformVal.length - 1)
        return Number(realVal)
        },
    num4Filter(value) {
        let transformVal = Number(value).toFixed(5)
        let realVal = transformVal.substring(0, transformVal.length - 1)
        return Number(realVal)
        },
    numberToCurrencyNo(value) {
      if (!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },
  },
  mounted() {
    let clientHeight = `${document.documentElement.clientHeight}`;
    this.clientHeight = `${clientHeight}` + "px";
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //let offsetTop = 0;
      let navoffsetTop = 66;

      if (scrollTop > navoffsetTop) {
        document.querySelector(".navbar").style.backgroundColor = "#000";
      } else {
        document.querySelector(".navbar").style.backgroundColor = "#000";
      }
    },
    imgUrl: function (path) {
      //console.log('Path:' + path);
      return logosContext(path);
    },
    onSwiper () {
    },
    onSlideChange () {
    },
    getCoinData: function() {
      this.$axios.get('/data.json').then(res => {     
        this.$set(this,'coinData',res.data)
        //console.log(this.coinData);
      })
    },
    async getKylData() {
      const [markets, charts] = await Promise.all([
        this.$axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=kylin-network&order=market_cap_desc&per_page=100&page=1&sparkline=false'),
        this.$axios.get('https://api.coingecko.com/api/v3/coins/kylin-network/market_chart?vs_currency=usd&days=1&interval=daily')
      ])
      console.log(markets)
      console.log(charts)
      const item = markets.data[0]
      const data = {
        price: item.current_price,
        circulating_supply: item.circulating_supply,
        market_cap: item.market_cap,
        fully_diluted_market_cap: item.fully_diluted_valuation,
        volume_24h: charts.data.total_volumes[charts.data.total_volumes.length - 1][1]
      }
      this.$set(this, 'coinData', data)
    },
    showCollapse: function() {
      const allElements = document.querySelectorAll('.el-collapse-item');
      //console.log(allElements);
      // for (var i = 0; i < allElements.length; i++) {
      //   var element = allElements[i];
      //   element.classList.toggle("highlight");
      //     console.log(element);
      // };

      allElements.forEach(function (elm) {
        elm.addEventListener("click", function () {
          //var panel = document.querySelector(".is-active");
          //panel.classList.toggle("is-active");
        if(!elm.classList.contains("is-active")) {
          elm.classList.add("is-active");
          elm.querySelector('.el-collapse-item__header').classList.add("is-active");
          //elm.querySelector('.el-collapse-item__wrap').setAttribute("style","display:flex");
        }else {

        }
        });
      });
      
    },
  },
};
</script>
