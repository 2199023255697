<template>
    <footer class="container">
      <el-row class="footer-row">
        <el-col :xs="24" :sm="24" :md="6">
          <img src="../assets/img/logo_footer.svg" alt="" />
          <p class="footer-logo-info">The Polkadot Data Blockchain</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="9">
          <el-row>
            <el-col :span="8">
              <div class="foot-link">
                <div class="foot-link-head">About</div>
                <ul>
                  <li @click="goAnchor('#technology')">Technology</li>
                  <li @click="goAnchor('#crowdloan')">Crowdloan</li>
                  <li @click="goAnchor('#tokenomics')">Tokenomics</li>
                  <li @click="goAnchor('#contact')">Contact Us</li>
                </ul>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="foot-link">
                <div class="foot-link-head">Resources</div>
                <ul>
                  <li><a href="https://wiki.kylin.network">Project Wiki</a></li>
                  <li><a href="https://docsend.com/view/kfrc2q57c3fwwkzs">Whitepaper</a></li>
                  <li><a href="https://docsend.com/view/2fwcfhs8mbyjnqcy">Overview Deck</a></li>
                  <li><a href="https://github.com/Kylin-Network">Github Repos</a></li>
                </ul>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="foot-link">
                <div class="foot-link-head">Foundation</div>
                <ul>
                  <li @click="goAnchor('#meet')">Team & Advisors</li>
                  <li @click="goAnchor('#ecosystem')">Ecosystem</li>
                  <li @click="goAnchor('#press')">Press about Us</li>
                  <li @click="goAnchor('#roadmap')">Roadmap</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <div>
          <div class="foot-link">
            <div class="foot-link-head">Contact</div>
            <div class="social">
              <div class="social-item">
                <a href="https://twitter.com/Kylin_Network">
                  <img src="../assets/img/social-twitter.svg" alt="twitter" />
                </a>
                <a href="#">
                  <img src="../assets/img/social-email.svg" alt="email" />
                </a>
                <a href="https://medium.com/kylin-network">
                  <img src="../assets/img/social_medium.svg" alt="medium" />
                </a>
              </div>
              <div class="social-item">
                <a href="https://t.me/KylinOfficial">
                  <img src="../assets/img/social_telegram.svg" alt="telegram" />
                </a>
                <a href="https://discord.com/invite/PwYCssr">
                  <img src="../assets/img/social_discord.svg" alt="discord" />
                </a>
                <a href="https://github.com/Kylin-Network">
                  <img src="../assets/img/social_github.svg" alt="github" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </el-row>
      <hr class="mt-5" />
      <p class="footer-copy">
        © Copyright {{ new Date().getFullYear() }}}. All Rights Reserved.
      </p>
    </footer>
</template>
<script>
export default {
  name: 'Footer',
  methods: {
    goAnchor(selector) {
        document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
        });
    },
  },
}
</script>
<style scoped lang="less">
.footer-row {
  &::before, &::after {
    content: none;
  }
}
.footer-info {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  margin-top: 40px;

  a {
    display: inline-flex;
  }
}
.footer-copy {
  font-size: 12px;
  margin-bottom: 0;
  padding-bottom: 70px;
}
</style>