<template>
  <div class="allpartners">
    <h1 class="text-center">Kylin Ecosystem</h1>
    <div class="partners-list container">
      <div class="row">
        <div v-for="item in partnerslogo" :key="item.id" class="col-sm-6 col-md-3">
          <div class="cstm-box">
            <div class="txt-box">
              <h4>{{ imgName(item) }}</h4>
              <img :src="imgUrl(item)" alt="" />
            </div>
			<div class="readmore" @click="toDetail(imgName(item))">Read More</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var logosContext = require.context("@/assets/partners", false, /\.png$/);
//console.log(logosContext.keys());
export default {
  created: function () {
    this.partnerslogo = logosContext.keys();
  },
  data() {
    return {
      partnerslogo: [],
	  res: null,
    };
  },
  name: "Partners",
  mounted () {
	  //this.$axios.get('/more.json').then(res => {  
        //console.log(res.data)
  //})
  },
  methods: {
    imgUrl: function (path) {
      //console.log('Path:' + path);
      return logosContext(path);
    },
	imgName: function (path) {
		return	path.replace(/^\.\//, '').replace(/\.\w+$/, '');
	},
	toDetail: function (name) {
		//this.$router.push({ path: "/partners/"+name });
		this.$router.push({ name: 'partnerdetail', params: { name }})

		// let routeData = this.$router.resolve({
		// 	name: "partnerdetail",
		// 	//query: params,
		// 	params:{name:params.name}
		// });
		// window.open(routeData.href, '_blank');
	}
  },
};
</script>
<style lang="less" scoped>
.allpartners {
  padding-top: 111px;
	h1 {
		padding: 5rem 0;
	}
}
.cstm-box {
		margin-bottom: 20px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
		.txt-box {
			padding-top: 15px;
			padding-right: 20px;
			padding-left: 20px;
			h4 {
				margin-bottom: 0px;
				padding-bottom: 15px;
				border-bottom: 1px solid #262626;
				font-family: Hauora, sans-serif;
				color: #fff;
				font-size: 18px;
				font-weight: 800;
				text-transform: uppercase;
				text-align: center;
			}
			img {
				width: 100%;
				margin-top: 0px;
				margin-bottom: 0px;
				padding: 0px;
				border-style: none;
				border-width: 2px;
				border-color: #fff;
				border-radius: 0px;
			}
	}
	.readmore {
		display: block;
		width: 100%;
		margin-left: 0px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		background-color: #e90373;
		font-family: Hauora, sans-serif;
		color: #fff;
		font-size: 14px;
		text-align: center;
		text-transform: uppercase;
		padding: 9px 15px;
		cursor: pointer;
		}
}
</style>